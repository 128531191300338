.nft-wrap {
  @apply text-base-100 text-sm leading-[22px] pt-6;
  .ntf-overview-wrap {
    @apply flex flex-col gap-4 md:flex-row;
  }
  .nft-image-wrap {
    @apply relative inline-flex justify-center items-start w-full h-auto;
    .explorer-image {
      @apply w-full rounded-2xl;
    }
    .nft-image {
      @apply object-fill w-full rounded-2xl;
    }
  }
  .nft-detail-wrap {
    @apply flex-1 min-[1025px]:min-w-[70%] md:min-w-[73%];
    .nft-title-wrap {
      @apply pb-4;
    }
    .nft-title {
      @apply font-medium text-xl mb-1;
    }
    .nft-thumb-image-wrap {
      @apply w-6 h-6 flex justify-center items-center relative;
    }
    .nft-thumb {
      @apply flex items-center gap-1;
    }
    .nft-detail {
      @apply bg-white rounded-lg border border-border;

      .nft-detail-label {
        @apply  p-4 flex items-center justify-between bg-muted rounded-lg;
        .nft-detail-label-left {
          @apply flex items-center gap-2 text-base text-muted-foreground;
        }

        .anticon {
          @apply rotate-180;
        }
      }
      .explorer-collapse-item-active {
        .nft-detail-label {
          @apply rounded-none rounded-t-lg;
          .anticon {
            @apply rotate-0;
          }
        }
      }
      .nft-detail-ul {
        @apply py-3 px-3;
      }
      .nft-detail-item {
        @apply py-3 flex justify-between;
      }
      .nft-detail-item-left {
        @apply flex w-[154px] lg:w-[312px] gap-1 text-muted-foreground;
        svg {
          @apply w-[14px] h-[14px];
        }
      }
      .nft-detail-item-right {
        @apply lg:flex-1 flex gap-1;
        svg {
          @apply w-[14px] h-[14px];
        }
      }
      .nft-detail-block-wrap {
        @apply gap-4 flex flex-wrap;
      }
      .nft-detail-block {
        @apply rounded-lg bg-ECEEF2 w-[138px] h-[58px] px-4 py-2 flex justify-center items-center flex-col;
        h1 {
          @apply text-sm leading-[22px] font-medium text-primary;
        }
      }
      .nft-detail-txt-checkbox {
        @apply hidden;
      }
      .nft-detail-txt {
        @apply line-clamp-5 break-all before:content-[''] before:float-right before:-mb-5 before:h-full;
        .nft-detail-txt-more {
          @apply float-right clear-both text-primary cursor-pointer;
        }
      }
    }
    .explorer-collapse-ghost {
      .explorer-collapse-item {
        border-bottom: 1px solid;
        @apply text-base !border-0 !border-b !border-color-divider last:!border-b-0;
      }
      .explorer-collapse-header {
        @apply !p-0;
      }
      .explorer-collapse-content-box {
        @apply !p-0;
      }
    }
  }

  .ntf-list-wrap {
    @apply mt-4;
  }
}
