.header-container {
  &-mobile {
    @apply w-full;
  }

  .explorer-affix {
    .header-top-container {
      @apply bg-white;

      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
    }
  }
}
